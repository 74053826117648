.cs-main-container {
  box-sizing: border-box;
  color: #000000de;
  background-color: #fff;
  border: 1px solid #d1dbe3;
  flex-direction: row;
  height: 100%;
  font-size: 1rem;
  display: flex;
  position: relative;
  overflow: auto;
}

.cs-main-container > .cs-conversation-list {
  z-index: 2;
  border: 0 solid #d1dbe3;
  border-right-width: 1px;
  flex-basis: 300px;
  order: 0;
  height: 100%;
  box-shadow: 2px 0 5px -2px #00000061;
}

.cs-main-container > .cs-sidebar.cs-sidebar--left {
  z-index: 2;
  border-right: 1px solid #d1dbe3;
  flex-basis: 35%;
  order: 0;
  max-width: 320px;
  height: 100%;
}

.cs-main-container > .cs-sidebar.cs-sidebar--right {
  border: 0 solid #d1dbe3;
  border-left-width: 1px;
  flex-basis: 25%;
  min-width: 150px;
  max-width: 320px;
}

.cs-main-container > .cs-sidebar.cs-sidebar--left .cs-search {
  margin: .5em;
}

.cs-main-container .cs-chat-container {
  z-index: 1;
  border-right: 0 solid #d1dbe3;
  flex-grow: 1;
  flex-basis: 65%;
  order: 1;
}

.cs-main-container .cs-sidebar {
  z-index: 3;
  order: 2;
  height: 100%;
}

.cs-main-container .cs-sidebar .cs-expansion-panel {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.cs-main-container .cs-sidebar .cs-expansion-panel:nth-last-child(3) {
  border-bottom: 0;
}

.cs-main-container .cs-conversation-header {
  z-index: 1;
}

.cs-main-container--responsive .cs-chat-container .cs-conversation-header .cs-conversation-header__back, .cs-main-container--responsive .cs-chat-container .cs-conversation-header .cs-conversation-header__actions .cs-button--info {
  display: none;
}

@media (width <= 768px) {
  .cs-main-container--responsive {
    min-width: calc(1.6em + 220px);
  }

  .cs-main-container--responsive .cs-search {
    display: none;
  }

  .cs-main-container--responsive > .cs-sidebar.cs-sidebar--left {
    flex-basis: calc(1.6em + 40px);
    min-width: calc(1.6em + 40px);
  }

  .cs-main-container--responsive > .cs-sidebar.cs-sidebar--right {
    display: none;
  }

  .cs-main-container--responsive > .cs-conversation-list {
    flex-basis: calc(1.6em + 40px);
  }

  .cs-main-container--responsive .cs-conversation-list .cs-conversation > .cs-avatar, .cs-main-container--responsive .cs-sidebar .cs-conversation-list .cs-conversation > .cs-avatar {
    margin-right: 0;
  }

  .cs-main-container--responsive .cs-conversation-list .cs-conversation__content, .cs-main-container--responsive .cs-sidebar .cs-conversation-list .cs-conversation__content, .cs-main-container--responsive .cs-conversation-list .cs-conversation__operations, .cs-main-container--responsive .cs-sidebar .cs-conversation-list .cs-conversation__operations, .cs-main-container--responsive .cs-conversation-list .cs-conversation__last-activity-time, .cs-main-container--responsive .cs-sidebar .cs-conversation-list .cs-conversation__last-activity-time {
    display: none;
  }

  .cs-main-container--responsive .cs-conversation-list .cs-conversation__unread-dot, .cs-main-container--responsive .cs-sidebar .cs-conversation-list .cs-conversation__unread-dot {
    margin-right: 0;
    position: absolute;
    top: .3em;
    right: .3em;
  }

  .cs-main-container--responsive .cs-conversation-header .cs-conversation-header__actions .cs-button--info {
    display: flex;
  }
}

@media (width <= 576px) {
  .cs-main-container--responsive {
    min-width: auto;
  }

  .cs-main-container--responsive > .cs-sidebar.cs-sidebar--left {
    display: none;
  }

  .cs-main-container--responsive .cs-chat-container .cs-conversation-header .cs-conversation-header__back {
    display: flex;
  }
}

.cs-message {
  box-sizing: border-box;
  color: #000000de;
  background-color: #0000;
  border-radius: 0;
  flex-direction: row;
  padding: 0;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  font-size: 1em;
  display: flex;
  overflow: hidden;
}

.cs-message:only-child, .cs-message:not(:only-child) {
  margin: .2em 0 0;
}

.cs-message__avatar {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-end;
  width: 42px;
  margin: 0 8px 0 0;
  display: flex;
}

.cs-message__content-wrapper {
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
}

.cs-message__header {
  box-sizing: border-box;
  color: #4d4d4dde;
  background-color: #0000;
  flex-flow: row;
  justify-content: space-between;
  margin: 0 .2em .1em;
  font-size: .8em;
  display: flex;
}

.cs-message__header .cs-message__sender-name {
  box-sizing: border-box;
  color: #4d4d4dde;
  background-color: #0000;
}

.cs-message__header .cs-message__sent-time {
  box-sizing: border-box;
  color: #4d4d4dde;
  background-color: #0000;
  margin-left: auto;
  padding-left: .8em;
}

.cs-message__footer {
  box-sizing: border-box;
  color: #4d4d4dde;
  background-color: #0000;
  margin: .1em .2em 0;
  font-size: .8em;
  display: flex;
}

.cs-message__footer .cs-message__sender-name {
  box-sizing: border-box;
  color: #4d4d4dde;
  background-color: #0000;
}

.cs-message__footer .cs-message__sent-time {
  box-sizing: border-box;
  color: #4d4d4dde;
  background-color: #0000;
  margin-left: auto;
  padding-left: .8em;
}

.cs-message__content {
  box-sizing: border-box;
  color: #000000de;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  word-break: break-word;
  font-variant: normal;
  background-color: #c6e3fa;
  border-radius: .7em;
  margin-top: 0;
  padding: .6em .9em;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  font-size: .91em;
  font-weight: normal;
}

.cs-message--incoming {
  color: #000000de;
  background-color: #0000;
  margin-right: auto;
}

.cs-message--incoming .cs-message__avatar {
  margin: 0 8px 0 0;
}

.cs-message--incoming .cs-message__sender-name, .cs-message--incoming .cs-message__sent-time {
  display: none;
}

.cs-message--incoming .cs-message__content {
  color: #000000de;
  background-color: #c6e3fa;
  border-radius: 0 .7em .7em 0;
}

.cs-message--outgoing {
  color: #000000de;
  background-color: #0000;
  justify-content: flex-end;
  margin-left: auto;
}

.cs-message--outgoing .cs-message__avatar {
  order: 1;
  margin: 0 0 0 8px;
}

.cs-message--outgoing .cs-message__sender-name, .cs-message--outgoing .cs-message__sent-time {
  display: none;
}

.cs-message--outgoing .cs-message__content {
  color: #000000de;
  background-color: #6ea9d7;
  border-radius: .7em 0 0 .7em;
}

.cs-message.cs-message--incoming.cs-message--single {
  border-radius: 0;
}

.cs-message.cs-message--incoming.cs-message--single:not(:first-child) {
  margin-top: .4em;
}

.cs-message.cs-message--incoming.cs-message--single .cs-message__sender-name, .cs-message.cs-message--incoming.cs-message--single .cs-message__sent-time {
  display: block;
}

.cs-message.cs-message--incoming.cs-message--single .cs-message__content {
  border-radius: 0 .7em .7em;
}

.cs-message.cs-message--incoming.cs-message--first {
  border-radius: 0;
}

.cs-message.cs-message--incoming.cs-message--first:not(:first-child) {
  margin-top: .4em;
}

.cs-message.cs-message--incoming.cs-message--first .cs-message__sender-name, .cs-message.cs-message--incoming.cs-message--first .cs-message__sent-time {
  display: block;
}

.cs-message.cs-message--incoming.cs-message--first .cs-message__content {
  background-color: #c6e3fa;
  border-radius: 0 .7em .7em 0;
}

.cs-message.cs-message--incoming.cs-message--last {
  border-radius: 0;
}

.cs-message.cs-message--incoming.cs-message--last .cs-message__sent-time {
  display: none;
}

.cs-message.cs-message--incoming.cs-message--last .cs-message__content {
  border-radius: 0 .7em;
}

.cs-message.cs-message--outgoing.cs-message--single {
  border-radius: 0;
}

.cs-message.cs-message--outgoing.cs-message--single:not(:first-child) {
  margin-top: .4em;
}

.cs-message.cs-message--outgoing.cs-message--single .cs-message__sent-time {
  display: block;
}

.cs-message.cs-message--outgoing.cs-message--single .cs-message__content {
  border-radius: .7em .7em 0;
}

.cs-message.cs-message--outgoing.cs-message--first {
  border-radius: 0;
  margin-top: .4em;
}

.cs-message.cs-message--outgoing.cs-message--first .cs-message__sent-time {
  display: block;
}

.cs-message.cs-message--outgoing.cs-message--first .cs-message__content {
  background-color: #6ea9d7;
  border-radius: .7em 0 0 .7em;
}

.cs-message.cs-message--outgoing.cs-message--last {
  border-radius: 0;
}

.cs-message.cs-message--outgoing.cs-message--last .cs-message__sent-time {
  display: none;
}

.cs-message.cs-message--outgoing.cs-message--last .cs-message__content {
  border-radius: .7em 0 .7em .7em;
}

.cs-message--incoming.cs-message--avatar-spacer {
  margin-left: 50px;
}

.cs-message--outgoing.cs-message--avatar-spacer {
  margin-right: 50px;
}

.cs-message--avatar-tl .cs-message__avatar {
  order: 0;
  justify-content: flex-start;
  margin-left: 0;
  margin-right: 8px;
}

.cs-message--avatar-tl .cs-message__message-wrapper {
  order: 1;
}

.cs-message--avatar-tr .cs-message__avatar {
  order: 1;
  justify-content: flex-start;
  margin-left: 8px;
  margin-right: 0;
}

.cs-message--avatar-tr .cs-message__message-wrapper {
  order: 0;
}

.cs-message--avatar-br .cs-message__avatar {
  order: 1;
  justify-content: flex-end;
  margin-left: 8px;
  margin-right: 0;
}

.cs-message--avatar-br .cs-message__message-wrapper {
  order: 0;
}

.cs-message--avatar-bl .cs-message__avatar {
  order: 0;
  justify-content: flex-end;
  margin-left: 0;
  margin-right: 8px;
}

.cs-message--avatar-bl .cs-message__message-wrapper {
  order: 1;
}

.cs-message--avatar-cl .cs-message__avatar {
  order: 0;
  justify-content: center;
  margin-left: 0;
  margin-right: 8px;
}

.cs-message--avatar-cl .cs-message__message-wrapper {
  order: 1;
}

.cs-message--avatar-cr .cs-message__avatar {
  order: 1;
  justify-content: center;
  margin-left: 8px;
  margin-right: 0;
}

.cs-message--avatar-cr .cs-message__message-wrapper {
  order: 0;
}

.cs-message-group {
  box-sizing: border-box;
  flex-direction: row;
  margin: .4em 0 0;
  display: flex;
}

.cs-message-group__avatar {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
}

.cs-message-group__content {
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
}

.cs-message-group__header {
  box-sizing: border-box;
  color: #0009;
  background-color: #0000;
  margin: 0 0 .2em;
  padding: 0;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  font-size: .8em;
  display: flex;
}

.cs-message-group__footer {
  box-sizing: border-box;
  color: #0009;
  background-color: #0000;
  margin: .2em 0 0;
  padding: 0;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  font-size: .8em;
  display: flex;
}

.cs-message-group__messages {
  box-sizing: border-box;
}

.cs-message-group__messages .cs-message:first-child {
  margin-top: 0;
}

.cs-message-group__messages .cs-message .cs-message__content {
  background-color: #c6e3fa;
  margin-top: 0;
}

.cs-message-group--incoming {
  justify-content: flex-start;
}

.cs-message-group--incoming .cs-message-group__avatar {
  order: 0;
  margin-right: 8px;
}

.cs-message-group--incoming .cs-message-group__content {
  order: 1;
}

.cs-message-group--incoming .cs-message-group__messages .cs-message:first-child .cs-message__content {
  border-radius: 0 .7em .7em 0;
}

.cs-message-group--incoming .cs-message-group__messages .cs-message .cs-message__content {
  color: #000000de;
  background-color: #c6e3fa;
  border-radius: 0 .7em .7em 0;
}

.cs-message-group--incoming .cs-message-group__messages .cs-message:last-child .cs-message__content {
  border-radius: 0 .7em;
}

.cs-message-group--incoming .cs-message-group__messages .cs-message:only-child .cs-message__content {
  border-radius: 0 .7em .7em;
}

.cs-message-group--outgoing {
  justify-content: flex-end;
  margin-left: auto;
}

.cs-message-group--outgoing .cs-message-group__avatar {
  order: 1;
  margin-left: 8px;
}

.cs-message-group--outgoing .cs-message-group__content {
  order: 0;
}

.cs-message-group--outgoing .cs-message-group__messages .cs-message {
  justify-content: flex-end;
}

.cs-message-group--outgoing .cs-message-group__messages .cs-message:first-child .cs-message__content {
  border-radius: .7em 0 0 .7em;
}

.cs-message-group--outgoing .cs-message-group__messages .cs-message .cs-message__content {
  color: #000000de;
  background-color: #6ea9d7;
  border-radius: .7em 0 0 .7em;
}

.cs-message-group--outgoing .cs-message-group__messages .cs-message:last-child .cs-message__content {
  border-radius: .7em 0 .7em .7em;
}

.cs-message-group--outgoing .cs-message-group__messages .cs-message:only-child .cs-message__content {
  border-radius: .7em .7em 0;
}

.cs-message-group--avatar-tl .cs-message-group__avatar {
  order: 0;
  justify-content: flex-start;
  margin-left: 0;
  margin-right: 8px;
}

.cs-message-group--avatar-tl .cs-message-group__content {
  order: 1;
}

.cs-message-group--avatar-tr .cs-message-group__avatar {
  order: 1;
  justify-content: flex-start;
  margin-left: 8px;
  margin-right: 0;
}

.cs-message-group--avatar-tr .cs-message-group__content {
  order: 0;
}

.cs-message-group--avatar-bl .cs-message-group__avatar {
  order: 0;
  justify-content: flex-end;
  margin-left: 0;
  margin-right: 8px;
}

.cs-message-group--avatar-bl .cs-message-group__content {
  order: 1;
}

.cs-message-group--avatar-br .cs-message-group__avatar {
  order: 1;
  justify-content: flex-end;
  margin-left: 8px;
  margin-right: 0;
}

.cs-message-group--avatar-br .cs-message-group__content {
  order: 0;
}

.cs-message-group--avatar-cl .cs-message-group__avatar {
  order: 0;
  justify-content: center;
  margin-left: 0;
  margin-right: 8px;
}

.cs-message-group--avatar-cl .cs-message-group__content {
  order: 1;
}

.cs-message-group--avatar-cr .cs-message-group__avatar {
  order: 1;
  justify-content: center;
  margin-left: 8px;
  margin-right: 0;
}

.cs-message-group--avatar-cr .cs-message-group__content {
  order: 0;
}

.cs-message-separator {
  box-sizing: border-box;
  color: #6ea9d7;
  text-align: center;
  background-color: #fff;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  font-size: .8em;
  display: flex;
}

.cs-message-separator:before, .cs-message-separator:after {
  box-sizing: border-box;
  content: "";
  background-color: #6ea9d7;
  flex-grow: 1;
  height: 1px;
  display: block;
}

.cs-message-separator:not(:empty):before {
  margin: 0 1em 0 0;
}

.cs-message-separator:not(:empty):after {
  margin: 0 0 0 1em;
}

.cs-message-list {
  box-sizing: border-box;
  color: #000000de;
  background-color: #fff;
  width: 100%;
  height: 100%;
  min-height: 1.25em;
  position: relative;
  overflow: hidden;
}

.cs-message-list__scroll-wrapper {
  box-sizing: border-box;
  padding: 0 1.2em 0 .8em;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.cs-message-list__scroll-to:first-child {
  box-sizing: border-box;
  float: left;
  clear: both;
  height: 0;
}

.cs-message-list__scroll-wrapper > .cs-message:nth-last-child(4), .cs-message-list__scroll-wrapper > .cs-message-group:nth-last-child(4) {
  margin-bottom: 2.65em;
}

.cs-message-list .cs-typing-indicator {
  background-color: #fff;
  height: 1.25em;
  padding: .5em;
  font-size: .9em;
  line-height: 1.25em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 15px;
}

.cs-message-list__scroll-wrapper > .cs-message, .cs-message-list__scroll-wrapper > .cs-message-group {
  max-width: 85%;
}

.cs-message-list .cs-message.cs-message--incoming, .cs-message-list .cs-message-group.cs-message-group--incoming {
  margin-right: auto;
}

.cs-message-list .cs-message.cs-message--outgoing, .cs-message-list .cs-message-group.cs-message-group--outgoing {
  margin-left: auto;
}

.cs-message-list .cs-message-separator:not(:first-child) {
  margin-top: 1.2em;
}

.cs-message-list__loading-more {
  box-sizing: content-box;
  z-index: 1;
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  height: 1.2em;
  padding: 2px 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.cs-message-list__loading-more .cs-loader {
  width: 1.2em;
  height: 1.2em;
}

.cs-message-list__loading-more .cs-loader:before, .cs-message-list__loading-more .cs-loader:after {
  width: 100%;
  height: 100%;
}

.cs-message-list__loading-more--bottom {
  top: initial;
  bottom: 0;
}

.cs-message-list .ps__rail-y {
  z-index: 2;
}

.cs-avatar {
  box-sizing: border-box;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  position: relative;
}

.cs-avatar > img {
  box-sizing: border-box;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.cs-avatar:hover > img {
  filter: brightness(115%);
}

.cs-avatar.cs-avatar--xs {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
}

.cs-avatar.cs-avatar--sm {
  width: 26px;
  min-width: 26px;
  height: 26px;
  min-height: 26px;
}

.cs-avatar.cs-avatar--md {
  width: 42px;
  min-width: 42px;
  height: 42px;
  min-height: 42px;
}

.cs-avatar.cs-avatar--lg {
  width: 68px;
  min-width: 68px;
  height: 68px;
  min-height: 68px;
}

.cs-avatar.cs-avatar--fluid {
  width: 100%;
  height: 100%;
}

.cs-avatar.cs-avatar--fluid .cs-status {
  width: 22%;
  height: 22%;
  right: 5%;
}

.cs-avatar .cs-status {
  box-sizing: border-box;
  position: absolute;
  bottom: 3%;
  right: -1px;
}

.cs-avatar .cs-status__bullet {
  box-sizing: content-box;
  border: 2px solid #fff;
}

.cs-avatar .cs-status--xs, .cs-avatar .cs-status--xs:not(.cs-status--named) {
  width: 6px;
  height: 6px;
  font-size: 1em;
  bottom: 0;
  right: 0;
}

.cs-avatar .cs-status--xs .cs-status__bullet, .cs-avatar .cs-status--xs:not(.cs-status--named) .cs-status__bullet {
  width: 4px;
  min-width: 4px;
  height: 4px;
}

.cs-avatar .cs-status--sm, .cs-avatar .cs-status--sm:not(.cs-status--named) {
  width: 12px;
  height: 12px;
  font-size: 1em;
  bottom: -1px;
  right: -3px;
}

.cs-avatar .cs-status--sm .cs-status__bullet, .cs-avatar .cs-status--sm:not(.cs-status--named) .cs-status__bullet {
  width: 8px;
  min-width: 8px;
  height: 8px;
}

.cs-avatar .cs-status--md, .cs-avatar .cs-status--md:not(.cs-status--named) {
  width: 15px;
  height: 15px;
  font-size: 1em;
}

.cs-avatar .cs-status--md .cs-status__bullet, .cs-avatar .cs-status--md:not(.cs-status--named) .cs-status__bullet {
  width: 11px;
  min-width: 11px;
  height: 11px;
}

.cs-avatar .cs-status--lg, .cs-avatar .cs-status--lg:not(.cs-status--named) {
  width: 18px;
  height: 18px;
  font-size: 1em;
  bottom: 4%;
}

.cs-avatar .cs-status--lg .cs-status__bullet, .cs-avatar .cs-status--lg:not(.cs-status--named) .cs-status__bullet {
  width: 14px;
  min-width: 14px;
  height: 14px;
}

.cs-avatar-group {
  box-sizing: border-box;
  flex-flow: row-reverse wrap;
  place-content: flex-start flex-end;
  min-width: 40px;
  display: flex;
  position: relative;
  overflow: visible;
}

.cs-avatar-group .cs-avatar--active {
  z-index: 1;
}

.cs-avatar-group .cs-avatar--active-on-hover:hover {
  z-index: 2;
}

.cs-avatar-group--xs {
  padding-top: 6.112px;
  padding-left: 6.112px;
}

.cs-avatar-group--xs .cs-avatar {
  border: none;
  border-right: 1px solid #f2f2f2;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  margin-top: -6.112px;
  margin-left: -6.112px;
}

.cs-avatar-group--sm {
  padding-top: 9.932px;
  padding-left: 9.932px;
}

.cs-avatar-group--sm .cs-avatar {
  width: 26px;
  min-width: 26px;
  height: 26px;
  min-height: 26px;
  margin-top: -9.932px;
  margin-left: -9.932px;
}

.cs-avatar-group--md {
  padding-top: 16.044px;
  padding-left: 16.044px;
}

.cs-avatar-group--md .cs-avatar {
  border: 2px solid #f2f2f2;
  width: 42px;
  min-width: 42px;
  height: 42px;
  min-height: 42px;
  margin-top: -16.044px;
  margin-left: -16.044px;
}

.cs-avatar-group--lg {
  padding-top: 25.976px;
  padding-left: 25.976px;
}

.cs-avatar-group--lg .cs-avatar {
  border: 2px solid #f2f2f2;
  width: 68px;
  min-width: 68px;
  height: 68px;
  min-height: 68px;
  margin-top: -25.976px;
  margin-left: -25.976px;
}

.cs-message-input {
  box-sizing: border-box;
  background-color: #fff;
  border: none;
  flex-direction: row;
  flex-shrink: 0;
  padding: 0;
  display: flex;
  position: relative;
  overflow: visible;
}

.cs-message-input__content-editor-wrapper {
  box-sizing: border-box;
  background-color: #c6e3fa;
  border-radius: .7em;
  flex-grow: 1;
  margin: 0;
  padding: .6em .9em;
  position: relative;
}

.cs-message-input--disabled .cs-message-input__content-editor-wrapper {
  color: #0009;
  background-color: #c6e3fa61;
}

.cs-message-input__content-editor-container {
  box-sizing: border-box;
  background-color: #c6e3fa;
  flex-direction: column;
  align-items: stretch;
  min-height: 1.35em;
  max-height: 5.4em;
  padding: 0;
  scroll-padding: 0;
  font-size: .94em;
  line-height: 1.35em;
  display: flex;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.cs-message-input--disabled .cs-message-input__content-editor-container {
  color: #0009;
  background-color: #0000;
}

.cs-message-input__content-editor {
  box-sizing: border-box;
  color: #000000de;
  overflow-wrap: anywhere;
  word-break: break-word;
  background-color: #c6e3fa;
  border: 0;
  outline: 0;
  flex-grow: 1;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  overflow: visible;
}

.cs-message-input--disabled .cs-message-input__content-editor {
  color: #0009;
  background-color: #0000;
}

.cs-message-input__content-editor[data-placeholder]:empty:before {
  box-sizing: border-box;
  content: attr(data-placeholder);
  color: #00000061;
  cursor: text;
  display: block;
}

.cs-message-input__tools {
  box-sizing: border-box;
  flex-direction: row;
  display: flex;
}

.cs-message-input__tools .cs-button {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.2em;
}

.cs-message-input__tools .cs-button--send, .cs-message-input__tools .cs-button--attachment {
  align-self: flex-end;
}

.cs-message-input__tools:first-child .cs-button:first-child {
  margin-left: 0;
}

.cs-message-input__tools:first-child .cs-button:last-child {
  margin-right: 0;
}

.cs-message-input__tools:last-child .cs-button:first-child {
  margin-left: 0;
}

.cs-message-input__tools:last-child .cs-button:last-child {
  margin-right: 0;
}

.cs-input-toolbox {
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0;
  padding: 0 0 .4em;
  display: flex;
}

.cs-input-toolbox .cs-button {
  background: none;
  border: none;
  margin: 0 .5em 0 0;
  padding: 0;
  font-size: 1.2em;
}

.cs-chat-container {
  box-sizing: border-box;
  color: #000000de;
  background-color: #fff;
  flex-direction: column;
  min-width: 180px;
  height: 100%;
  display: flex;
}

.cs-chat-container .cs-message-input {
  color: #000000de;
  background-color: #fff;
  border: 0 solid #d1dbe3;
  border-top-width: 1px;
  margin-top: auto;
  padding: .3em 0 .3em 0;
}

.cs-chat-container .cs-message-input .cs-message-input__content-editor-wrapper:first-child {
  margin-left: .8em;
}

.cs-chat-container .cs-message-input .cs-message-input__content-editor-wrapper:last-child {
  margin-right: .8em;
}

.cs-chat-container .cs-input-toolbox {
  color: #000000de;
  background-color: #fff;
  margin: 0;
  padding: 0 .8em .17em 0;
}

.cs-typing-indicator {
  box-sizing: content-box;
  font-size: inherit;
  flex-direction: row;
  align-items: stretch;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  display: flex;
}

.cs-typing-indicator__indicator {
  box-sizing: inherit;
  background-color: #0000;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.cs-typing-indicator__dot {
  box-sizing: inherit;
  background-color: #6ea9d7;
  border-radius: 100%;
  width: 4px;
  height: 4px;
  animation: 1.5s ease-in-out infinite cs-typing-indicator__typing-animation;
  display: inline-block;
}

.cs-typing-indicator__dot:not(:last-child) {
  margin-right: 3px;
}

.cs-typing-indicator__dot:first-child {
  box-sizing: inherit;
}

.cs-typing-indicator__dot:nth-child(2) {
  animation-delay: .3s;
}

.cs-typing-indicator__dot:nth-child(3) {
  animation-delay: .4s;
}

.cs-typing-indicator__text {
  box-sizing: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #6ea9d7;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  margin: 0 0 0 .5em;
  overflow: hidden;
}

@keyframes cs-typing-indicator__typing-animation {
  0% {
    transform: translateY(0);
  }

  28% {
    transform: translateY(-5px);
  }

  44% {
    transform: translateY(0);
  }
}

.cs-conversation-header {
  box-sizing: border-box;
  color: #000000de;
  -webkit-user-select: none;
  user-select: none;
  background-color: #f6fbff;
  border: 0 solid #d1dbe3;
  border-bottom-width: 1px;
  flex-direction: row;
  flex-shrink: 0;
  align-items: stretch;
  padding: .6em .9em;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  font-size: 1em;
  display: flex;
}

.cs-conversation-header__back {
  box-sizing: border-box;
  cursor: pointer;
  flex-direction: row;
  order: 1;
  align-items: center;
  margin-right: .5em;
  display: flex;
}

.cs-conversation-header__back button {
  padding: 0;
  font-size: 1.4em;
}

.cs-conversation-header__avatar {
  box-sizing: border-box;
  order: 2;
  width: 42px;
  height: 42px;
  margin-right: 1em;
}

.cs-conversation-header__avatar > .cs-avatar > .cs-status > .cs-status__bullet {
  border-color: #f6fbff;
}

.cs-conversation-header__avatar .cs-avatar-group {
  padding-right: 0 !important;
}

.cs-conversation-header__content {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 2;
  order: 3;
  justify-content: center;
  min-width: 0;
  display: flex;
}

.cs-conversation-header__content .cs-conversation-header__user-name {
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000000de;
  background-color: #f6fbff;
  font-weight: bold;
  overflow: hidden;
}

.cs-conversation-header__content .cs-conversation-header__info {
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #0009;
  background-color: #f6fbff;
  font-size: .9em;
  font-weight: normal;
  overflow: hidden;
}

.cs-conversation-header__actions {
  box-sizing: border-box;
  color: #000000de;
  background-color: #0000;
  flex-direction: row;
  order: 4;
  align-items: center;
  margin: 0 0 0 .5em;
  display: flex;
}

.cs-conversation-header__actions .cs-button:not(:first-child) {
  margin-left: .2em;
}

.cs-conversation-header__actions .cs-button {
  padding: 0;
}

.cs-conversation-header__actions .cs-button.cs-button--arrow, .cs-conversation-header__actions .cs-button.cs-button--info, .cs-conversation-header__actions .cs-button.cs-button--voicecall, .cs-conversation-header__actions .cs-button.cs-button--videocall, .cs-conversation-header__actions .cs-button.cs-button--star, .cs-conversation-header__actions .cs-button.cs-button--adduser, .cs-conversation-header__actions .cs-button.cs-button--ellipsis, .cs-conversation-header__actions .cs-button.cs-button--ellipsis {
  color: normal;
  background-color: #0000;
  font-size: 1.4em;
}

.cs-conversation-header__actions .cs-button.cs-button--ellipsis {
  font-size: 1.3em;
}

.cs-conversation {
  box-sizing: border-box;
  cursor: pointer;
  color: #000000de;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 0;
  flex-direction: row;
  justify-content: flex-start;
  padding: .675em .8em;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  display: flex;
  position: relative;
}

.cs-conversation:hover {
  background-color: #f3f8fc;
}

.cs-conversation:hover > .cs-avatar > .cs-status > .cs-status__bullet {
  border-color: #f3f8fc;
}

.cs-conversation:hover > .cs-avatar > img {
  filter: none;
}

.cs-conversation.cs-conversation:active {
  color: #000000de;
  background-color: #d5e7f5;
  border: 0;
}

.cs-conversation.cs-conversation:active > .cs-avatar > .cs-status > .cs-status__bullet {
  border-color: #d5e7f5;
}

.cs-conversation.cs-conversation--active {
  color: #2b6a9b;
  background-color: #d5e7f5;
  border: 0;
  font-weight: normal;
}

.cs-conversation.cs-conversation--active > .cs-avatar > .cs-status > .cs-status__bullet {
  border-color: #d5e7f5;
}

.cs-conversation.cs-conversation--active .cs-conversation__name, .cs-conversation.cs-conversation--active .cs-conversation__info {
  color: #2b6a9b;
  font-weight: normal;
}

.cs-conversation > .cs-avatar {
  width: 42.1px;
  height: 42.1px;
  margin-right: 1em;
}

.cs-conversation > .cs-avatar > .cs-status {
  border-color: #fff;
}

.cs-conversation > .cs-avatar-group {
  width: 42.1px;
  min-width: 42.1px;
  height: 42.1px;
  min-height: 42.1px;
  margin-right: 1em;
  padding-right: 0 !important;
}

.cs-conversation__content {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 1.1em;
  display: flex;
  overflow: hidden;
}

.cs-conversation__operations {
  box-sizing: border-box;
  color: #b3b3b3de;
  visibility: hidden;
  flex-direction: row;
  align-items: center;
  margin: 0 0 0 auto;
  display: flex;
}

.cs-conversation__operations--visible {
  visibility: visible;
}

.cs-conversation__operations:hover {
  color: #666666de;
}

.cs-conversation:hover .cs-conversation__operations {
  visibility: visible;
}

.cs-conversation__name {
  box-sizing: border-box;
  color: #000000de;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1em;
  overflow: hidden;
}

.cs-conversation__info {
  box-sizing: border-box;
  color: #0009;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: .8em;
  overflow: hidden;
}

.cs-conversation__last-sender, .cs-conversation__info-content {
  box-sizing: border-box;
  font-weight: normal;
  display: inline;
}

.cs-conversation__last-activity-time {
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  margin: 0 0 0 auto;
  font-size: .8em;
  display: flex;
}

.cs-conversation__last-activity-time + .cs-conversation__operations {
  margin-left: .5em;
}

.cs-conversation__unread-dot {
  box-sizing: content-box;
  perspective: 200px;
  perspective-origin: 50%;
  background: radial-gradient(circle at 3px 3px, #7c9df1, #2e63e8);
  border-radius: 50%;
  flex-direction: row;
  align-self: center;
  align-items: center;
  width: .7em;
  min-width: .7em;
  height: .7em;
  margin-right: .5em;
  display: flex;
  box-shadow: 1px 1px 1px #b3b3b3;
}

.cs-conversation__unread-dot:hover:before {
  content: "";
  z-index: 2;
  filter: blur();
  background: radial-gradient(circle at 130% 130%, #fff0 0 46%, #fffc 50% 58%, #fff0 60% 100%);
  border-radius: 50%;
  width: 40%;
  height: 80%;
  position: absolute;
  top: 1%;
  left: 5%;
  transform: translateX(131%)translateY(58%)rotateZ(168deg)rotateX(10deg);
}

.cs-conversation__unread-dot:hover:after {
  content: "";
  filter: blur(1px);
  z-index: 2;
  background: radial-gradient(circle at 50% 80%, #fff0, #fff0 74%, #fff 80% 84%, #fff0 100%);
  border-radius: 100%;
  width: 80%;
  height: 80%;
  animation: 2s ease-out infinite cs-unread-anim;
  display: block;
  position: absolute;
  top: 5%;
  left: 10%;
  transform: rotateZ(-30deg);
}

.cs-conversation__unread {
  box-sizing: border-box;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 100;
  background-color: #ec1212;
  border-radius: .3em;
  max-width: 30em;
  padding: .01em .3em;
  font-size: .75em;
  font-weight: 600;
  position: absolute;
  top: .3em;
  right: .8em;
  overflow: hidden;
}

@keyframes cs-unread-anim {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scaleY(.95)scaleX(1.05);
  }

  48% {
    transform: scaleY(1.1)scaleX(.9);
  }

  68% {
    transform: scaleY(.98)scaleX(1.02);
  }

  80% {
    transform: scaleY(1.02)scaleX(.98);
  }

  97%, 100% {
    transform: scale(1);
  }
}

.cs-conversation-list {
  box-sizing: border-box;
  color: #000000de;
  background-color: #0000;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.cs-conversation-list > div > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.cs-conversation-list__loading-more {
  box-sizing: content-box;
  z-index: 1;
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  height: 1.2em;
  padding: 2px 0;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.cs-conversation-list__loading-more .cs-loader {
  width: 1.2em;
  height: 1.2em;
}

.cs-conversation-list__loading-more .cs-loader:before, .cs-conversation-list__loading-more .cs-loader:after {
  width: 100%;
  height: 100%;
}

.cs-conversation-list .ps__rail-y {
  z-index: 2;
}

.cs-status {
  box-sizing: border-box;
  flex-flow: row;
  align-items: center;
  font-size: 1em;
  display: flex;
  position: relative;
}

.cs-status .cs-status__bullet {
  box-sizing: content-box;
  perspective: 200px;
  perspective-origin: 50%;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  position: relative;
}

.cs-status--selected {
  color: #2b6a9b;
  background-color: #d5e7f5;
}

.cs-status--named {
  width: auto;
  height: auto;
}

.cs-status__name {
  margin: 0 0 0 .58em;
  line-height: 1.5;
}

.cs-avatar:hover .cs-status__bullet:before, .cs-status:hover .cs-status__bullet:before {
  box-sizing: border-box;
  content: "";
  z-index: 2;
  filter: blur();
  background: radial-gradient(circle at 130% 130%, #fff0 0 46%, #fffc 50% 58%, #fff0 60% 100%);
  border-radius: 50%;
  width: 40%;
  height: 80%;
  position: absolute;
  top: 1%;
  left: 5%;
  transform: translateX(131%)translateY(58%)rotateZ(168deg)rotateX(10deg);
}

.cs-avatar:hover .cs-status__bullet:after, .cs-status:hover .cs-status__bullet:after {
  box-sizing: border-box;
  content: "";
  filter: blur(1px);
  z-index: 2;
  background: radial-gradient(circle at 50% 80%, #fff0, #fff0 74%, #fff 80% 84%, #fff0 100%);
  border-radius: 100%;
  width: 80%;
  height: 80%;
  animation: 2s ease-out infinite cs-bubble-anim;
  display: block;
  position: absolute;
  top: 5%;
  left: 10%;
  transform: rotateZ(-30deg);
}

.cs-status--available .cs-status__bullet {
  background: radial-gradient(circle at 3px 3px, #00d5a6, #00a27e);
}

.cs-status--available .cs-status__name {
  color: #000000de;
}

.cs-status--unavailable .cs-status__bullet {
  background: radial-gradient(circle at 3px 3px, #ffb527, #a66d00);
}

.cs-status--unavailable .cs-status__name {
  color: #000000de;
}

.cs-status--away .cs-status__bullet {
  background: radial-gradient(circle at 3px 3px, #ffdbb0, #fc8b00);
}

.cs-status--away .cs-status__name {
  color: #000000de;
}

.cs-status--dnd .cs-status__bullet {
  background: radial-gradient(circle at 3px 3px, #f89f9f, #ec1212);
}

.cs-status--dnd .cs-status__name {
  color: #000000de;
}

.cs-status--invisible .cs-status__bullet {
  background: radial-gradient(circle at 3px 3px, #fff, #c2d1d9);
}

.cs-status--invisible .cs-status__name {
  color: #000000de;
}

.cs-status--eager .cs-status__bullet {
  background: radial-gradient(circle at 3px 3px, #fffccc, #fe0);
}

.cs-status--eager .cs-status__name {
  color: #000000de;
}

.cs-status--xs:not(.cs-status--named) {
  width: 4px;
  height: 4px;
  font-size: 1em;
}

.cs-status--xs:not(.cs-status--named) .cs-status__bullet {
  width: 4px;
  min-width: 4px;
  height: 4px;
}

.cs-status--sm:not(.cs-status--named) {
  width: 8px;
  height: 8px;
  font-size: 1em;
}

.cs-status--sm:not(.cs-status--named) .cs-status__bullet {
  width: 8px;
  min-width: 8px;
  height: 8px;
}

.cs-status--md:not(.cs-status--named) {
  width: 11px;
  height: 11px;
  font-size: 1em;
}

.cs-status--md:not(.cs-status--named) .cs-status__bullet {
  width: 11px;
  min-width: 11px;
  height: 11px;
}

.cs-status--lg:not(.cs-status--named) {
  width: 14px;
  height: 14px;
  font-size: 1em;
}

.cs-status--lg:not(.cs-status--named) .cs-status__bullet {
  width: 14px;
  min-width: 14px;
  height: 14px;
}

.cs-status--fluid {
  width: 100%;
  height: 100%;
}

.cs-status--fluid .cs-status__bullet {
  width: 100%;
  min-width: 100%;
  height: 100%;
}

@keyframes cs-bubble-anim {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scaleY(.95)scaleX(1.05);
  }

  48% {
    transform: scaleY(1.1)scaleX(.9);
  }

  68% {
    transform: scaleY(.98)scaleX(1.02);
  }

  80% {
    transform: scaleY(1.02)scaleX(.98);
  }

  97%, 100% {
    transform: scale(1);
  }
}

.cs-sidebar {
  box-sizing: border-box;
  flex-direction: column;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.cs-sidebar--left, .cs-sidebar--right {
  background-color: #fff;
}

.cs-sidebar .cs-expansion-panel + .cs-expansion-panel {
  border-top: 0;
}

.cs-expansion-panel {
  box-sizing: border-box;
  color: #000000de;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: inherit;
  font-variant: normal;
  background-color: #fff;
  border: 1px solid #d1dbe3;
  flex-direction: column;
  display: flex;
}

.cs-expansion-panel__header {
  box-sizing: border-box;
  color: #0009;
  font-variant: normal;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  background-color: #f6fbff;
  flex-direction: row;
  align-items: center;
  padding: .8em;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  font-size: .75em;
  font-weight: bold;
  display: flex;
}

.cs-expansion-panel__header:hover {
  color: #0009;
  background-color: #f3f8fc;
}

.cs-expansion-panel__title {
  box-sizing: border-box;
  flex-grow: 1;
}

.cs-expansion-panel__icon {
  box-sizing: border-box;
  margin-left: 1em;
  margin-right: .5em;
}

.cs-expansion-panel__content {
  color: #000000de;
  font-variant: normal;
  background-color: #fff;
  padding: .4em .8em;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  font-size: .8em;
  font-weight: normal;
  display: none;
}

.cs-expansion-panel--open .cs-expansion-panel__content {
  color: #000000de;
  background-color: #fff;
  display: block;
}

.cs-expansion-panel--closed .cs-expansion-panel__content {
  display: none;
}

.cs-expansion-panel--open .cs-expansion-panel__header {
  color: #0009;
  background-color: #f6fbff;
}

.cs-search {
  box-sizing: border-box;
  font-size: inherit;
  background-color: #c6e3fa;
  border-radius: .7em;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: .6em .9em;
  font-family: inherit;
  display: flex;
  position: relative;
}

.cs-search__input {
  box-sizing: border-box;
  color: #000000de;
  background-color: #c6e3fa;
  border: none;
  outline: 0;
  order: 1;
  width: 100%;
  min-width: 0;
  margin-right: .5em;
  font-family: inherit;
  font-size: .8em;
}

.cs-search__input:disabled {
  color: #00000061;
  background-color: #c6e3fa;
}

.cs-search__input::placeholder {
  color: #000000de;
}

.cs-search__search-icon {
  box-sizing: border-box;
  color: #6ea9d7;
  order: 0;
  margin-right: .5em;
  display: block;
}

.cs-search__clear-icon {
  box-sizing: border-box;
  color: #6ea9d7;
  visibility: hidden;
  order: 2;
}

.cs-search__clear-icon:hover {
  color: #6ea9d799;
  cursor: pointer;
}

.cs-search__clear-icon--active {
  visibility: visible;
}

.cs-search--disabled {
  opacity: .38;
  color: #00000061;
  background-color: #c6e3fa;
}

.cs-button {
  box-sizing: border-box;
  vertical-align: middle;
  text-align: center;
  color: #6ea9d7;
  background-color: #0000;
  border: none;
  border-radius: .7em;
  outline: none;
  margin: .1em .2em;
  padding: .2em .7em;
  font-size: 1em;
  line-height: 1.5;
  display: inline-block;
}

.cs-button:focus {
  outline: none;
}

.cs-button:disabled {
  opacity: .38;
}

.cs-button:not(:disabled) {
  cursor: pointer;
}

.cs-button.cs-button--border {
  border: 1px solid #d1dbe3;
}

.cs-button:hover:not(:disabled) {
  opacity: .6;
}

.cs-button.cs-button--right > svg[data-icon]:first-child {
  margin-right: .5em;
}

.cs-button.cs-button--left > svg[data-icon]:last-child {
  margin-left: .5em;
}

.cs-button--adduser, .cs-button--arrow, .cs-button--ellipsis, .cs-button--info, .cs-button--star, .cs-button--videocall, .cs-button--voicecall, .cs-button--send, .cs-button--attachment {
  color: #6ea9d7;
  background-color: #0000;
}

.cs-loader {
  box-sizing: border-box;
  color: #000000de;
  background-color: #0000;
  width: 1.8em;
  height: 1.8em;
  font-size: 1em;
  display: inline-flex;
  position: relative;
}

.cs-loader:before, .cs-loader:after {
  box-sizing: border-box;
  width: 1.8em;
  height: 1.8em;
  margin: 0 0 0 -.9em;
  display: block;
}

.cs-loader:before {
  content: "";
  border: .2em solid #c6e3fa;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
}

.cs-loader:after {
  content: "";
  border: .2em solid #0000;
  border-top-color: #6ea9d7;
  border-radius: 50%;
  animation: .6s linear infinite loader-default;
  position: absolute;
  top: 0;
  left: 50%;
  box-shadow: 0 0 #0000;
}

.cs-loader--content {
  width: auto;
  height: auto;
  padding-top: 2.6em;
}

@keyframes loader-default {
  to {
    transform: rotate(360deg);
  }
}

.cs-overlay {
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(10%);
  backdrop-filter: blur(10%);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.cs-overlay__content {
  z-index: 220;
}

.cs-overlay:before {
  box-sizing: border-box;
  content: "";
  z-index: 200;
  background-color: #c6e3fa61;
  position: absolute;
  inset: 0;
}

.cs-overlay--blur:before {
  backdrop-filter: blur(2px);
}

.cs-overlay--grayscale:before {
  backdrop-filter: grayscale(50%);
}

.cs-status-list {
  box-sizing: border-box;
  color: #000000de;
  background-color: #fff;
  margin: 0;
  padding: 1em;
  list-style-type: none;
}

.cs-status-list > li {
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.cs-status-list > li > .cs-status {
  width: auto;
  height: auto;
  margin: 0;
  padding: .4em .8em;
}

.cs-status-list > li .cs-status--selected, .cs-status-list > li .cs-status--selected .cs-status__name {
  color: #2b6a9b;
  background-color: #d5e7f5;
}

.cs-status-list > li:hover {
  color: #0009;
  background-color: #f3f8fc;
}

.cs-status-list > li:focus, .cs-status-list > li:active {
  color: #000000de;
  background-color: #d5e7f5;
}

.cs-status-list--xs > li > .cs-status {
  width: auto;
  height: auto;
  font-size: 1em;
}

.cs-status-list--xs > li > .cs-status .cs-status__bullet {
  width: 4px;
  min-width: 4px;
  height: 4px;
}

.cs-status-list--sm > li > .cs-status {
  width: auto;
  height: auto;
  font-size: 1em;
}

.cs-status-list--sm > li > .cs-status .cs-status__bullet {
  width: 8px;
  min-width: 8px;
  height: 8px;
}

.cs-status-list--md > li > .cs-status {
  width: auto;
  height: auto;
  font-size: 1em;
}

.cs-status-list--md > li > .cs-status .cs-status__bullet {
  width: 11px;
  min-width: 11px;
  height: 11px;
}

.cs-status-list--lg > li > .cs-status {
  width: auto;
  height: auto;
  font-size: 1em;
}

.cs-status-list--lg > li > .cs-status .cs-status__bullet {
  width: 14px;
  min-width: 14px;
  height: 14px;
}

.ps {
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
  overflow: hidden !important;
}

.ps__rail-x {
  opacity: 0;
  height: 15px;
  transition: background-color .2s linear, opacity .2s linear;
  display: none;
  position: absolute;
  bottom: 0;
}

.ps__rail-y {
  opacity: 0;
  width: 15px;
  transition: background-color .2s linear, opacity .2s linear;
  display: none;
  position: absolute;
  right: 0;
  left: auto !important;
}

.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
  background-color: #0000;
  display: block;
}

.ps:hover > .ps__rail-x, .ps:hover > .ps__rail-y, .ps--focus > .ps__rail-x, .ps--focus > .ps__rail-y, .ps--scrolling-x > .ps__rail-x, .ps--scrolling-y > .ps__rail-y {
  opacity: .6;
}

.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
  opacity: .9;
  background-color: #f6fbff;
}

.ps__thumb-x {
  background-color: #6ea9d7;
  border-radius: 6px;
  height: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  position: absolute;
  bottom: 2px;
}

.ps__thumb-y {
  background-color: #c6e3fa;
  border-radius: 6px;
  width: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  position: absolute;
  right: 2px;
}

.ps__rail-x:hover > .ps__thumb-x, .ps__rail-x:focus > .ps__thumb-x, .ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #c6e3fa;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #c6e3fa;
  width: 11px;
}

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

.scrollbar-container {
  height: 100%;
  position: relative;
}

/*# sourceMappingURL=index.cad21d68.css.map */
